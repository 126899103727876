<template>
  <v-data-table
      style="border-radius: 10px!important"
      v-bind:headers="headers"
      :items="items"
      class="ma-0 pa-0 elevation-4"
    >
    <template class="ma-0 pa-0" slot="headerCell" slot-scope="props">
      <v-tooltip bottom>
        <span slot="activator" class="ma-0 pa-0 teal--text ">
          {{ props.header.text }}
        </span>
        <span>
          {{ props.header.text }}
        </span>
      </v-tooltip>
    </template>
    <template slot="items" slot-scope="props">
      <v-dialog width="500px" > 
        <td class="ma-0 pa-2 pt-3 primary--text" slot="activator" style="cursor: pointer">{{ props.item.member }}</td>
        <v-card> 
            <v-card-title class="headline primary white--text" primary-title > Edit Membership</v-card-title> 
            <v-card-text> 
              <v-layout wrap>
                  <v-flex xs12 sm12 md6 lg6 >
                      <v-layout row class="grey--text darken-2 mb-2">
                          <v-flex xs2 sm1>
                              <i class="teal--text fas fa-calendar-days fa-2x mt-2"> </i>                            
                          </v-flex>
                          <v-flex xs10 sm10>
                              <v-text-field
                                  class="pt-0 mt-3" color="teal"
                                  v-model="member" ref="member"
                                  disabled
                              ></v-text-field>
                          </v-flex>
                      </v-layout>
                  </v-flex>
                  <v-flex xs12 sm12 md6 lg6 >
                      <v-layout row class="grey--text darken-2 mb-2">
                          <v-flex xs2 sm1>
                              <i class="teal--text fas fa-calendar-days fa-2x mt-2"> </i>                            
                          </v-flex>
                          <v-flex xs10 sm10>
                              <v-select
                                  class="pt-0 mt-3" color="teal"
                                  v-model="membership_role" ref="membership_role"
                                  :items="membership_roleItems"
                                  :rules="[v => !!v || 'Membership Role is required']"
                                  label="Membership Role"
                                  required
                              ></v-select>
                          </v-flex>
                      </v-layout>
                  </v-flex>
                  <v-flex xs12 sm12 md6 lg6 >
                      <v-layout row class="grey--text darken-2 mb-2">
                          <v-flex xs2 sm1>
                              <i class="teal--text fas fa-calendar-days fa-2x mt-2"> </i>                            
                          </v-flex>
                          <v-flex xs10 sm10>
                              <v-select
                                  class="pt-0 mt-3" color="teal"
                                  v-model="membership_status" ref="membership_status"
                                  :items="membership_statusItems"
                                  :rules="[v => !!v || 'Member\'s Status is required']"
                                  label="Member's Status"
                                  required
                              ></v-select>
                          </v-flex>
                      </v-layout>
                  </v-flex>
                  <v-flex xs12 sm12 md6 lg6 >
                      <v-layout row class="grey--text darken-2 mb-2">
                          <v-flex xs2 sm1>
                              <i class="teal--text fas fa-coins fa-2x mt-2"> </i>                            
                          </v-flex>
                          <v-flex xs10 sm10>
                              <v-text-field class="pa-0"
                                  id="membership_position" v-model="membership_position" ref="membership_position"
                                  :rules="[v => !!v || 'Member\'s Position is required']"
                                  label="Member's Position"
                                  color="teal"
                              ></v-text-field>
                          </v-flex>
                      </v-layout>
                  </v-flex>
              </v-layout>
            </v-card-text> 
            <v-divider></v-divider> 
            <v-card-actions> 
                <v-spacer></v-spacer> 
                <v-btn color="primary" flat @click="dialog = false" > Validate </v-btn> 
            </v-card-actions> 
        </v-card> 
      </v-dialog> 
      <td class="ma-0 pa-0 pl-2 text-xs-left">{{ props.item.status }}</td>
      <td class="ma-0 pa-0 pr-2 text-xs-left">{{ props.item.role }}</td>
      <td class="ma-0 pa-0 pr-2 text-xs-left">{{ props.item.position }}</td>
    </template>
  </v-data-table>
</template>

<script>
  import axios from 'axios'

  export default {
    data () {
      return {
        dialog: false,
        member: 'Member\'s Name',
        membership_status: "",
        membership_statusItems: [
          'Active',
          'Inactive',
          'Deactivated',
          'Pending',
        ],
        membership_role: "",
        membership_roleItems: [
          'Admin',
          'President',
          'Financial Secretary',
          'Member',
        ],
        membership_position: "",
        headers: [
          {
            text: 'Member',
            align: 'left',
            sortable: false,
            value: 'member'
          },
          { 
              text: 'Status',
              align: 'left',
              sortable: false,
              value: 'status' 
          },
          { 
              text: 'Role',
              align: 'left',
              sortable: false,
              value: 'role' 
          },
          { 
              text: 'Position',
              align: 'left',
              sortable: false,
              value: 'position' 
          },
        ],
        items: []
      }
    },
    mounted(){
        // executes these after the page has been mounted
        document.title = "PettyCash Njangi | Membership"
        this.getNjangiGroupMembers()
    },
    methods:{
        async getNjangiGroupMembers(){
            this.$store.commit('setIsLoading', true)
            const group_id = this.$route.params.new_group_id  // get group id from url and send request to server
        
            await axios
                .get('/api/v1/njangi/get/membership/list/'+group_id+'/')
                .then(response => {
                    this.items = response.data
                    console.log(response.data)
                })
                .catch(error => {
                    if (error.response){
                        this.$store.commit('setSnackBarMessage', error.response.status + " " + error.response.statusText)
                        this.$store.commit('setSnackBarColor', "red darken-4")
                        this.$store.commit('activateSnackBar', true)
                    }else{
                        this.$store.commit('setSnackBarMessage', "An error occured, please check your internet connection and try again later.")
                        this.$store.commit('setSnackBarColor', "red darken-4")
                        this.$store.commit('activateSnackBar', true)
                    }
                })
            this.$store.commit('setIsLoading', false)
        },
    },
  }
</script>

<style >
  th{
    padding: 12px!important;
  }
</style>
